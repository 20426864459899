<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-text>
            <h1>No tiene permisos para acceder aquí.</h1>
            <p class='mt-6'>Si cree que es un error contacte a soporte <span class='fw-bold'>berrocaljhoy@gmail.com</span> Jhoy Labs KV</p>
            <div class='text-center mt-5'>
              <img class='img-dog' src='/bad.webp' alt='unauthorized'>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

};
</script>

<style lang='scss' scoped>
.img-dog {
  width: 100%;
  max-width: 400px;
}
</style>
