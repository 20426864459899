import { render, staticRenderFns } from "./not-permission.vue?vue&type=template&id=6e509ad1&scoped=true&"
import script from "./not-permission.vue?vue&type=script&lang=js&"
export * from "./not-permission.vue?vue&type=script&lang=js&"
import style0 from "./not-permission.vue?vue&type=style&index=0&id=6e509ad1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e509ad1",
  null
  
)

export default component.exports